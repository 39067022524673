<template>
  <b-card inline style="">
    <b-card-body>
      <div class="container-xl">
        <div class="row">
          <div style="margin-top: 25px">
            <div>
              <h3>Search Filters</h3>
            </div>
            <label><b>Search Keyword</b></label>
            <b-input v-model="form.search" placeholder="Keyword" style="width: 215px"></b-input
            ><br /><br />
            <label><b>Type</b></label>
            <b-checkbox>For Sale</b-checkbox><br />
            <b-checkbox>For Rent</b-checkbox><br /><br />
            <label><b>Price Range</b></label>
            <b-form inline>
              <b-input v-model="form.min" style="width: 92px" placeholder="Min Price"></b-input>
              <label style="margin: 10px"> to </label>
              <b-input
                style="width: 92px"
                placeholder="Max Price"
                v-model="form.max"
              ></b-input> </b-form
            ><br /><br />
            <b-form-select v-model="form.location" :options="locations" style="width: 215px"></b-form-select
            ><br /><br /><br />
            <label><b>Fitness Score</b></label
            ><br />
            <input v-model="form.score" type="range" style="width: 2.2in" min="0" max="5" />
            <br /><br /><br />
            <b-button class="btn" @click="searchIt()" variant="info">Search</b-button>
          </div>
          <div class="col-sm" style="margin-left: 20px">
            <h2 style="margin: 25px">Browse Results</h2>
            <b-select class="select" style="width: 200px">
              <b-select-option>Sort by</b-select-option>
              <b-select-option>Price Low to High</b-select-option>
              <b-select-option>Price High to Low</b-select-option>
            </b-select>
            <div class="text-center mt-4">
              <b-container class="wd-50">
                <b-row>
                  <b-col v-for="list in listing" :key="list.id" cols="12" md="4">
                    <b-card
                      :title="list.name"
                      img-src="@/assets/images/gallery/power.jpg"
                      img-alt="Image"
                      img-top
                      tag="article"
                      class="mb-2 border"
                    >
                      <b-card-text>
                        <b-badge variant="info">{{list.product_type === "sale"? "For Sale":"To Rent"}}</b-badge>&nbsp;<b-badge variant="info">{{ getLocation(list.location_id) }}</b-badge><br>
                        <b>DHs {{showing==='resources'? list.product_type === "sale"? list.purchase_price:list.rental_price :list.rate_price}}</b>
                      </b-card-text>
                      <b-button v-if="showing==='resources'" variant="warning" :to="{ path:'/product/' + list.user_id + '/' + list.id }"  size="sm" style="margin: 10px">Details</b-button>
                      <b-button v-else variant="warning" :to="{ path:'/service/' + list.user_id + '/' + list.id }"  size="sm" style="margin: 10px">Details</b-button>
                    </b-card>
                  </b-col>
                </b-row>
                <b-pagination style="margin-left: 3.5in"></b-pagination>
              </b-container>
            </div>
          </div>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    name: "Listing",
    data: () => ({
      mainProps: {
        center: false,
        fluidGrow: false,
        blank: true,
        blankColor: '#bbb',
        width: 250,
        height: 200,
        class: ''
      },
      form: {
        search: "",
        min: 0,
        max: 999999999999,
        location: 0,
        category: 0,
        score: 5,
        cat: ""
      },
      products: [],
      services: [],
      listing: [],
      locations: [],
      categories: [],
      showing: null
    }),
    computed: {},
    mounted() {
      this.initComp();
    },
    methods: {
        ...mapActions(["getResources", "getServices", "getCategories", "getLocations", "searchItems"]),
        initComp(){
          this.showing = this.$route.params.type;
          this.getCategories().then((res) => {
            res.forEach(cat => {
              this.categories.push(cat);
            });
          })
          this.getLocations().then((res) => {
            res.forEach(element => {
              this.locations.push({ value: element.id, text: element.name }); 
            });
          })
          if(this.$route.params.cat !== "all"){
            this.form.cat = this.$route.params.cat;
            this.searchItems(this.form).then((res) => {
              this.listing = res.resources
            })  
          }
          else if(this.$route.params.type === "resources"){
            this.getResources().then(res => {
                this.listing = res
            });
          }
          else if(this.$route.params.type === "services"){
            this.getServices().then(res => {
                this.listing = res
            });
          }
        },
        getLocation(id){
          return this.locations.find(l => l.value === id).text
        },
        searchIt(){
          this.searchItems(this.form).then((res) => {
            if(this.$route.params.type === "resources"){
              this.listing = res.resources
            }
            else if(this.$route.params.type === "services"){
              this.listing = res.services
            }
          })
        }
    }
};
</script>
<style>
.select {
  margin-left: 25px;
}
</style>
